import { useState } from 'react'
import { useMount } from 'react-use'
import { t } from '@lingui/macro'
import { getMemberAreaIp } from '@/apis/user'
import { MemberMemberAreaType } from '@/typings/user'
import { UserValidateMethodEnum, UserVerifyTypeEnum, ChinaAreaCodeEnum } from '@/constants/user'

const useAreaIp = () => {
  const [area, setArea] = useState<MemberMemberAreaType>({
    codeVal: ChinaAreaCodeEnum.code,
    codeKey: t`features_user_personal_center_account_security_phone_index_2432`,
    remark: ChinaAreaCodeEnum.remark,
  })

  const getAreaIp = async () => {
    const res = await getMemberAreaIp({})
    if (res.isOk) {
      const { enCode, fullName, shortName } = res.data
      setArea({
        codeVal: enCode,
        codeKey: fullName,
        remark: shortName,
      })
    }
  }

  useMount(getAreaIp)

  return area
}

export default useAreaIp
